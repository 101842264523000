<template>
<div class="card-box-full">
  <div class="card-box-header">
    Services
    <div style="float: right; margin-right: 10px;">
      <div v-if="this.refreshTimer != null" style="cursor: pointer" @click="clickedPause()">
        <CIcon name="cil-media-pause" style="width: 20px; height: 20px" />
      </div>
      <div v-else style="cursor: pointer" @click="clickedPlay()">
        <CIcon name="cil-media-play" style="width: 20px; height: 20px" />
      </div>
    </div>
  </div>


  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing && services.length == 0">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="services.length> 0">
    <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
      <thead>
        <tr style="font-size: 13px; border: 2px solid transparent">
          <th>Service</th>
          <th>Instances</th>
          <th style="text-align:center">Oldest</th>
          <th style="text-align:center">Newest</th>
          <th style="text-align:right">Last Seen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="service in services" style="font-size: 13px" class="clickable-row" @click="clickedService(service.name)">
          <td>
            <div style="color: #3A9CFC; font-weight: 700">{{ service.name }}</div>
          </td>
          <td align="left" >
            <CIcon v-for="instance in service.instances" name="cil-screen-desktop" style="margin-right: 2px" />
          </td>
          <td align="center">
            <span class="badge badge-pill badge-light">
              <timeago :datetime="service.oldest_instance"></timeago>
            </span>
          </td>
          <td align="center">
            <span class="badge badge-pill badge-light">
              <timeago :datetime="service.newest_instance"></timeago>
            </span>
          </td>
          <td align="right">
            <transition name="fade" mode="out-in">
              <div :key="service.last_seen.toString()">
                <span class="badge badge-pill badge-primary">{{ service.last_seen | moment("h:mm:ss a") }}</span>

              </div>
            </transition>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


</div>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      errorMessage: null,
      isRefreshing: false,
      refreshTimer: null,
      services: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedService(service) {
      this.$router.push({
        path: '/services/' + service
      });
    },
    //--------------------------------------------------------------------------
    clickedPause() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    //--------------------------------------------------------------------------
    clickedPlay() {
      if (this.refreshTimer == null) {
        this.refresh();
        var self = this;
        this.refreshTimer = setInterval(function() {
          self.refresh();
        }, 1000);
      }
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {

      // Make sure we have data
      if (!response || !response.services) {
        this.errorMessage = "No services were returned";
        this.services = [];
        return;
      }

      // Parse the services
      var serviceMap = new Map();
      response.services.forEach((s) => {
        if (!serviceMap.has(s.name)) {
          serviceMap.set(s.name, {
            name: s.name,
            instances: 0,
            oldest_instance: new Date(8640000000000000),
            newest_instance: new Date(-8640000000000000),
            last_seen: new Date(-8640000000000000)
          })
        };
        var service = serviceMap.get(s.name);

        // Process the Instances
        s.instances.forEach((instance) => {
          service.instances++;
          var lastSeen = Date.parse(instance.last_seen);
          if (lastSeen > service.last_seen) {
            service.last_seen = lastSeen;
          }
          var launched = Date.parse(instance.launched);
          if (launched < service.oldest_instance) {
            service.oldest_instance = launched;
          }
          if (launched > service.newest_instance) {
            service.newest_instance = launched;
          }
        });
        serviceMap.set(service.name, service);
      });

      // Update the services array
      var updatedServices = [];
      for (let [key, value] of serviceMap) {
        updatedServices.push(value);
      }

      // Sort the services by name
      updatedServices.sort((a,b) => a.name > b.name ? 1 : -1);

      this.errorMessage = null;
      this.services = updatedServices;
    },
    //--------------------------------------------------------------------------
    refresh: function() {
      // Make sure we're not currently refreshing
      if (this.isRefreshing) {
        return;
      } else {
        this.isRefreshing = true;
      }

      // Fetch the telemetry data
      var that = this;
      var method = "GET";
      var url = "https://telemetry.platform.robocalls.ai/services";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
             that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    }
  },
  mounted() {
    // Every second refresh the telemetry data
    this.clickedPlay();
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }
}
</script>
